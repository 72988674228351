import "./Footer.css";

export const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer__container container">
                <h1 className="footer__title">Oscar Alvarado</h1>

                <ul className="footer__list">
                    <li>
                        <a href="#about" className="footer__link">About</a>
                    </li>
                    <li>
                        <a href="#portfolio" className="footer__link">Projects</a>
                    </li>
                    <li>
                        <a href="#testimonials" className="footer__link">Testimonials</a>
                    </li>
                </ul>

                <div className="footer__social">

                    <a href="https://github.com/osma95" className="footer__social-link" target='_blank'> <i class="uil uil-github-alt"></i> </a>
                    <a href="https://www.linkedin.com/in/oscarmag/" className="footer__social-link" target='_blank'> <i class="uil uil-linkedin"></i> </a>

                </div>

                <span className="footer__copy">&#169; All rights reserved. Oscar Alvarado 2024 ®</span>

            </div>
        </footer>
    );
};