// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollup {
    position: fixed;
    right: 2.5rem;
    bottom: -20%;
    background-color: var(--title-color);
    opacity: 0.8;
    padding: 0.3rem 0.5rem;
    border-radius: 0.4rem;
    z-index: var(--z-tooltip);
    transition: .4s;
}

.scrollup__icon {
    font-size: 1.5rem;
    color: var(--container-color);
}

.scrollup:hover {
    background-color: var(--title-color-dark);
}

/* Show Scroll */
.show-scroll {
    bottom: 3rem;
}

/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .scrollup {
        right: 1.5rem;
        padding: 0.25rem 0.4rem;
    }

    .scrollup__icon {
        font-size: 1.25rem;
    }
}

/* For medium devices */
@media screen and (max-width: 768px) {
    .show-scroll{
        bottom: 4rem;
    }
}

/* For small devices */
@media screen and (max-width: 350px) {
    .scrollup{
        right: 1rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/ScrollUp/ScrollUp.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,aAAa;IACb,YAAY;IACZ,oCAAoC;IACpC,YAAY;IACZ,sBAAsB;IACtB,qBAAqB;IACrB,yBAAyB;IACzB,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,6BAA6B;AACjC;;AAEA;IACI,yCAAyC;AAC7C;;AAEA,gBAAgB;AAChB;IACI,YAAY;AAChB;;AAEA,8CAA8C;AAC9C,sBAAsB;AACtB;IACI;QACI,aAAa;QACb,uBAAuB;IAC3B;;IAEA;QACI,kBAAkB;IACtB;AACJ;;AAEA,uBAAuB;AACvB;IACI;QACI,YAAY;IAChB;AACJ;;AAEA,sBAAsB;AACtB;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":[".scrollup {\r\n    position: fixed;\r\n    right: 2.5rem;\r\n    bottom: -20%;\r\n    background-color: var(--title-color);\r\n    opacity: 0.8;\r\n    padding: 0.3rem 0.5rem;\r\n    border-radius: 0.4rem;\r\n    z-index: var(--z-tooltip);\r\n    transition: .4s;\r\n}\r\n\r\n.scrollup__icon {\r\n    font-size: 1.5rem;\r\n    color: var(--container-color);\r\n}\r\n\r\n.scrollup:hover {\r\n    background-color: var(--title-color-dark);\r\n}\r\n\r\n/* Show Scroll */\r\n.show-scroll {\r\n    bottom: 3rem;\r\n}\r\n\r\n/*=============== BREAKPOINTS ===============*/\r\n/* For large devices */\r\n@media screen and (max-width: 992px) {\r\n    .scrollup {\r\n        right: 1.5rem;\r\n        padding: 0.25rem 0.4rem;\r\n    }\r\n\r\n    .scrollup__icon {\r\n        font-size: 1.25rem;\r\n    }\r\n}\r\n\r\n/* For medium devices */\r\n@media screen and (max-width: 768px) {\r\n    .show-scroll{\r\n        bottom: 4rem;\r\n    }\r\n}\r\n\r\n/* For small devices */\r\n@media screen and (max-width: 350px) {\r\n    .scrollup{\r\n        right: 1rem;\r\n    }\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
