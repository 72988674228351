const Image1 = "https://firebasestorage.googleapis.com/v0/b/oscargeneral-5b51c.appspot.com/o/PortafolioProgramacionOscarAlvarado%2Fprofile.jpeg?alt=media&token=f680cad8-8749-4a01-86d3-5f870e4a8c78"
const Image2 = "https://firebasestorage.googleapis.com/v0/b/oscargeneral-5b51c.appspot.com/o/PortafolioProgramacionOscarAlvarado%2Fprofile.jpeg?alt=media&token=f680cad8-8749-4a01-86d3-5f870e4a8c78"
const Image3 = "https://firebasestorage.googleapis.com/v0/b/oscargeneral-5b51c.appspot.com/o/PortafolioProgramacionOscarAlvarado%2Fprofile.jpeg?alt=media&token=f680cad8-8749-4a01-86d3-5f870e4a8c78"

export const Data = [
    {
      id: 1,
      image: Image1,
      title: "Jhon Doe",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 2,
      image: Image2,
      title: "Harry Clinton",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
    {
      id: 3,
      image: Image3,
      title: "Sara Cill",
      description:
        "A really good job, all aspects of the project were followed step by step and with good results.",
    },
  ];