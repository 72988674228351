import './App.css';
import React from 'react';
import { Footer } from './Components/Footer/Footer';
import { Header } from './Components/Header/Header';
import { ScrollUp } from './Components/ScrollUp/ScrollUp';
import { Testimonials } from './Components/Testimonials/Testimonials';

const Home = React.lazy(() => import('./Components/Home/Home'));
const About = React.lazy(() => import('./Components/About/About'));
const Skills = React.lazy(() => import('./Components/Skills/Skills'));
const Services = React.lazy(() => import('./Components/Services/Services'));
const Qualification = React.lazy(() => import('./Components/Qualification/Qualification'));
const Work = React.lazy(() => import('./Components/Work/Work'));
const Contact = React.lazy(() => import('./Components/Contact/Contact'));

function App() {
  return (
    <>
      <Header />
      <main className="main">
        <React.Suspense fallback='Loading...'><Home /></React.Suspense>
        <React.Suspense fallback='Loading...'><About /></React.Suspense>
        <React.Suspense fallback='Loading...'><Skills /></React.Suspense>
        <React.Suspense fallback='Loading...'><Services /></React.Suspense>
        <React.Suspense fallback='Loading...'><Qualification /></React.Suspense>
        <React.Suspense fallback='Loading...'><Work /></React.Suspense>
        <React.Suspense fallback='Loading...'><Contact /></React.Suspense>
      </main>
      <Footer />
      <ScrollUp />
    </>
  );
}
export default App;