// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.testimonial__container {
    width: 750px;
}

.testimonial__card {
    background-color: var(--container-color);
    border: 1px solid rgba(0,0,0,0.1);
    padding: 1.5rem 2rem;
    border-radius: 1.5rem;
    margin-bottom: var(--mb-3);
}

.testimonial__img {
    width: 60px;
    border-radius: 3rem;
    margin-bottom: var(--mb-1);
}

.testimonial__name {
    font-size: var(--normal-font-size);
    font-weight: var(--font-medium);
    margin-bottom: var(--mb-0-25);
}

.testimonial__description {
    font-size: var(--small-font-size);
}

/*=============== SWIPER PAGINATION ===============*/
.swiper-pagination-bullet{
    background-color: var(--text-color) !important;
}

.swiper-pagination-bullet-active{
    background-color: var(--title-color) !important;
}



/*=============== BREAKPOINTS ===============*/
/* For large devices */
@media screen and (max-width: 992px) {
    .testimonial__container{
        width: initial;
    }

    .testimonial__card{
        padding: 1.25rem 1.5rem;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/Testimonials/Testimonials.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,wCAAwC;IACxC,iCAAiC;IACjC,oBAAoB;IACpB,qBAAqB;IACrB,0BAA0B;AAC9B;;AAEA;IACI,WAAW;IACX,mBAAmB;IACnB,0BAA0B;AAC9B;;AAEA;IACI,kCAAkC;IAClC,+BAA+B;IAC/B,6BAA6B;AACjC;;AAEA;IACI,iCAAiC;AACrC;;AAEA,oDAAoD;AACpD;IACI,8CAA8C;AAClD;;AAEA;IACI,+CAA+C;AACnD;;;;AAIA,8CAA8C;AAC9C,sBAAsB;AACtB;IACI;QACI,cAAc;IAClB;;IAEA;QACI,uBAAuB;IAC3B;AACJ","sourcesContent":[".testimonial__container {\r\n    width: 750px;\r\n}\r\n\r\n.testimonial__card {\r\n    background-color: var(--container-color);\r\n    border: 1px solid rgba(0,0,0,0.1);\r\n    padding: 1.5rem 2rem;\r\n    border-radius: 1.5rem;\r\n    margin-bottom: var(--mb-3);\r\n}\r\n\r\n.testimonial__img {\r\n    width: 60px;\r\n    border-radius: 3rem;\r\n    margin-bottom: var(--mb-1);\r\n}\r\n\r\n.testimonial__name {\r\n    font-size: var(--normal-font-size);\r\n    font-weight: var(--font-medium);\r\n    margin-bottom: var(--mb-0-25);\r\n}\r\n\r\n.testimonial__description {\r\n    font-size: var(--small-font-size);\r\n}\r\n\r\n/*=============== SWIPER PAGINATION ===============*/\r\n.swiper-pagination-bullet{\r\n    background-color: var(--text-color) !important;\r\n}\r\n\r\n.swiper-pagination-bullet-active{\r\n    background-color: var(--title-color) !important;\r\n}\r\n\r\n\r\n\r\n/*=============== BREAKPOINTS ===============*/\r\n/* For large devices */\r\n@media screen and (max-width: 992px) {\r\n    .testimonial__container{\r\n        width: initial;\r\n    }\r\n\r\n    .testimonial__card{\r\n        padding: 1.25rem 1.5rem;\r\n    }\r\n}\r\n\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
